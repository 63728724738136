<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title v-if="!isEmployee">My Course Schedule</v-toolbar-title>
      <v-toolbar-title v-else-if="workstudyList.length === 0">Student Course Schedule</v-toolbar-title>
      <v-menu v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ placement === '' || placement == null ? 'Student Course Schedule' : workstudyList.find(({ code }) => code === placement).title }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item to="/student/schedule" exact>
            <v-list-item-content>
              <v-list-item-title>Student Course Schedule</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="{ code, title, count } in workstudyList" :key="code" :to="'/student/schedule/' + code" :disabled="count === 0">
            <v-list-item-content>
              <v-list-item-title>{{ title }}</v-list-item-title>
              <v-list-item-subtitle>{{ count }} Student{{ count === 1 ? '' : 's' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <template v-if="isEmployee">
        <v-btn to="schedule/admin">Schedule Change Admin</v-btn>
      </template>
      <v-btn v-else-if="!isXSmall" href="https://www.covenant.edu/pdf/visit/campus_map.pdf" target="_blank" text>
        <v-icon left>fas fa-map-marked</v-icon>
        Campus Map
      </v-btn>
      <v-menu v-if="terms.length > 1">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ termLabel }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="{ label, term: newTerm } in terms" :key="newTerm" @click="term = newTerm">
            <v-list-item-title>{{ label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else-if="term !== ''" disabled text>{{ termLabel }}</v-btn>
    </v-toolbar>
    <workstudy-schedule v-if="isEmployee && workstudyList.length > 0 && placement"></workstudy-schedule>
    <template v-else>
      <v-card-text v-if="isEmployee">
        <directory-search :value="bannerId" :person-filter="['Student']" search-label="Student Search" value-field="bannerId" @select="loadStudentSchedule"></directory-search>
      </v-card-text>
      <v-alert v-if="isLoaded && terms.length === 0 && !isEmployee" type="error">It looks like you are not registered for any upcoming terms. Please use Banner for your initial course registration. If you need further assistance, please contact the Records office.</v-alert>
      <v-tabs v-else-if="isLoaded && terms.length > 0" v-model="tab">
        <v-tab>
          <v-icon left>fas fa-list</v-icon>
          <span v-if="!isXSmall">List View</span>
        </v-tab>
        <v-tab>
          <v-icon left>fas fa-calendar</v-icon>
          <span v-if="!isXSmall">Calendar View</span>
        </v-tab>
        <v-tab v-if="!termEnded && !isEmployee">
          <v-icon left>fal fa-calendar-edit</v-icon>
          <span v-if="!isXSmall">Make Changes</span>
        </v-tab>
        <v-tab v-if="hasChanges && !isEmployee">
          <v-icon left>fal fa-clock</v-icon>
          <span v-if="!isXSmall">Requested Changes</span>
        </v-tab>
        <v-spacer></v-spacer>
        <v-btn v-if="changeId && isEmployee" :to="`/student/schedule/${changeId}`">View Schedule Change Request</v-btn>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <list-view :term="term" :schedule="schedule" :updated-at="lastUpdated"></list-view>
        </v-tab-item>
        <v-tab-item>
          <calendar-view :term="term" :term-start="termRec ? termRec.start : ''" :schedule="schedule"></calendar-view>
        </v-tab-item>
        <v-tab-item v-if="!termEnded && !isEmployee">
          <schedule-change :term="term" :schedule="schedule" :messages="termRec ? termRec.messages : []" :academics="termRec ? termRec.academics : { classLevel: 'FR' }" @changed="madeChanges"></schedule-change>
        </v-tab-item>
        <v-tab-item v-if="hasChanges && !isEmployee">
          <timeline ref="timelineRef" :term="term" :schedule-updated-at="lastUpdated"></timeline>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>
<style>
.v-slide-group__prev--disabled, .v-slide-group__next--disabled {
  display: none !important;
}
.toolbar-title-button {
  cursor: pointer;
  border: 1px solid rgba(0,0,0,.01)
}
.toolbar-title-button:hover {
  border: 1px solid #DEDEDE;
  background-color: #DEDEDE;
}
</style>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    WorkstudySchedule: () => import('@/components/student/schedule/admin/wsCalendar'),
    DirectorySearch: () => import('@/components/greatscots/searchField'),
    ListView: () => import('@/components/student/schedule/list'),
    CalendarView: () => import('@/components/student/schedule/calendar'),
    ScheduleChange: () => import('@/components/student/schedule/changes'),
    Timeline: () => import('@/components/student/schedule/timeline')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isDark = computed(() => user.value.settings.darkMode || false)
    const roles = computed(() => root.$store.state.roles)
    const isEmployee = computed(() => user.value.roles.includes('Employee'))

    const isLoaded = ref(false)
    const terms = ref([])
    const term = ref('')
    const termRec = computed(() => terms.value.find(({ term: dTerm }) => dTerm === term.value))
    const termLabel = computed(() => termRec.value != null ? termRec.value.label : '**Select Term**')
    const termStarted = computed(() => termRec.value != null && new Date(termRec.value.start) < new Date())
    const termEnded = computed(() => termRec.value != null && new Date(termRec.value.end) < new Date())
    const schedule = computed(() => termRec.value != null ? termRec.value.schedule : [])
    const classes = computed(() => termRec.value != null ? termRec.value.classes : [])
    const lastUpdated = computed(() => termRec.value != null ? stringFormatDate(termRec.value.updatedAt) : '')

    const bannerId = computed(() => root.$route.params.bannerId)
    const pidm = ref(null)
    const placement = computed(() => root.$route.params.placement)
    const workstudyList = ref([])

    onMounted(async () => {
      if (isEmployee.value) {
        if (bannerId.value !== '' && bannerId.value != null) await load()
        // Check to see if the user has access to a work-study list in the directory
        const aggregate = [
          { $match: { type: 'workstudy', 'share.user': user.value.directoryId } },
          { $project: {
            code: 1,
            title: 1,
            group: { $first: '$groups' }
          } },
          { $project: {
            code: 1,
            title: 1,
            count: { $size: '$group.people' }
          } }
        ]
        const { data } = await root.$feathers.service('directory/list').find({ query: { aggregate } })
        workstudyList.value = data
        if (placement.value != null && placement.value !== '') {
          // Check to make sure the placement code is one that they have access to; if not, go back to the student search
          if (data.filter(({ code }) => code === placement.value).length === 0) {
            root.$router.replace('/student/schedule')
          }
        }
      } else {
        pidm.value = user.value.pidm
        await load()
      }
    })

    function loadStudentSchedule ({ value }) {
      if (value !== bannerId.value && value != null && value !== '') root.$router.push('/student/schedule/' + value)
    }

    watch(bannerId, async (bannerId) => {
      if (bannerId == null || bannerId === '') return
      const { data } = await root.$feathers.service('directory/people').find({ query: { bannerId } })
      if (data.length > 0) {
        pidm.value = data[0].pidm
        await load()
      }
    })

    async function load () {
      isLoaded.value = false
      const aggregate = [
        { $match: { pidm: pidm.value, 'schedule.0': { $exists: true } } },
        { $project: {
          term: 1,
          bannerId: 1,
          name: 1,
          schedule: 1,
          academics: 1,
          updatedAt: 1
        } },
        { $lookup: {
          from: 'Terms',
          localField: 'term',
          foreignField: 'term',
          as: 'termDetail'
        } },
        { $lookup: {
          from: 'Calendar-Classes',
          localField: 'term',
          foreignField: 'term',
          as: 'classes',
          pipeline: [
            { $unwind: '$students' },
            { $match: { 'students.pidm': pidm.value } },
            { $project: { crn: 1, title: 1, section: 1, credits: 1, instructor: { $first: '$instructors' }, meetingBase: 1, regStatus: '$students.regStatus' } },
            {
              $project: {
                crn: 1,
                title: 1,
                credits: 1,
                instructor: '$instructor.name',
                regStatus: 1,
                meets: {
                  $reduce: {
                    input: '$meetingBase',
                    initialValue: '',
                    in: {
                      $concat: [
                        '$$value',
                        { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                        { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                        { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                        '$$this.endTime',
                        { $cond: [
                          { $eq: ['$$this.room', null] },
                          '',
                          { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                        ] }
                      ]
                    }
                  }
                }
              }
            },
            { $project: { crn: 1, title: 1, credits: 1, instructor: 1, regStatus: 1, meets: { $cond: [{ $eq: ['$meets', ''] }, '**No Meeting Times Specified**', '$meets'] } } }
          ]
        } },
        { $project: {
          term: 1,
          bannerId: 1,
          name: 1,
          schedule: 1,
          academics: 1,
          updatedAt: 1,
          detail: { $first: '$termDetail' },
          classes: 1
        } },
        { $project: {
          term: 1,
          bannerId: 1,
          name: 1,
          schedule: 1,
          academics: 1,
          updatedAt: 1,
          label: '$detail.label',
          start: '$detail.start',
          end: '$detail.end',
          messages: '$detail.scheduleChangeMessages',
          classes: 1
        } },
        { $sort: {
          term: -1
        } }
      ]
      let { data } = await root.$feathers.service('student/term-detail').find({ query: { aggregate } })
      terms.value = data.map((rec) => {
        const schedule = []
        // Combine the schedule and classes arrays, making sure to match on CRN
        for (const sched of rec.schedule) {
          const { crn } = sched
          const cls = rec.classes.find(({ crn: classCrn }) => classCrn === crn)
          schedule.push({ ...sched, ...cls })
        }
        return { ...rec, schedule }
      })
      if (data.length > 0) {
        const now = new Date()
        // Go through the term list and see if there is an in-progress term; if so, choose that one. If not, choose the next one (or last if they are not registered for any future terms)
        for (const { term: dTerm, start, end } of data) {
          if (new Date(end) > now) {
            if (new Date(start) < now) {
              term.value = dTerm
              break
            } else {
              term.value = dTerm
            }
          }
        }
        if (term.value === '') {
          term.value = terms.value[terms.value.length - 1].term
        }
      }
      isLoaded.value = true
    }

    const changeId = ref(null)
    const hasChanges = ref(false)
    watch(term, (newTerm) => {
      if (newTerm == null || newTerm === '') return
      // Check to see if they have a schedule change submission for the selected term
      loadChanges()
    })
    async function loadChanges () {
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { pidm: pidm.value, term: term.value } })
      if (data.length > 0) {
        changeId.value = data[0]._id
        hasChanges.value = true
        if (timelineRef.value) {
          timelineRef.value.load()
        }
      } else {
        hasChanges.value = false
      }
    }
    async function madeChanges () {
      await loadChanges()
      tab.value = 3
    }

    const tab = ref(0)
    const timelineRef = ref(null)
    const isXSmall = computed(() => root.$vuetify.breakpoint.name === 'xs')
    const isSmall = computed(() => ['xs', 'sm'].includes(root.$vuetify.breakpoint.name))

    return {
      user,
      isDark,
      roles,
      isEmployee,
      isLoaded,
      terms,
      term,
      termRec,
      termLabel,
      termStarted,
      termEnded,
      schedule,
      lastUpdated,
      bannerId,
      pidm,
      placement,
      workstudyList,
      loadStudentSchedule,
      load,
      classes,
      changeId,
      hasChanges,
      loadChanges,
      madeChanges,
      tab,
      isXSmall,
      isSmall,
      stringFormatDate
    }
  }
}
</script>
